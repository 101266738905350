<template>
  <div class="E3D-video">
    <swiper :banners="banners"></swiper>
    <div class="E3D-content">
      <div class="E3D-video-introduction"> {{ navList.abstract }} </div>
      <breadcrumb :refer="refer"></breadcrumb>

      <!-- E3D 剑法秘籍：一站式进阶学习 -->
      <div class="module-item">
        <div class="module-item-title">
          <img src="@/assets/PC/fenceDetail/praise1.png" alt="">
          <h1>E3D 剑法秘籍：一站式进阶学习</h1>
          <img src="@/assets/PC/fenceDetail/praise1.png" alt="">
        </div>
        <div class="module-item-nav flex2">
          <div class="module-item-nav-item flex2" v-for="item in navList.e3DItems" :key="item.id" :class="item.id === params.id ? 'module-item-nav-item-active' : ''" :style="{width: 'calc(100% /' + (navList.e3DItems.length + 0.25) + ')'}">
            <div class="module-item-nav-line" style="width: 25%"></div>
            <div class="module-item-nav-text tc" @click="tabType(item.id)">
              {{ item.title }} <br v-if="clientWidth > 768"/> {{ clientWidth > 768 ? item.subTitle : '' }} <br/> {{ item.moduleNum }}门
            </div>
          </div>
          <div class="module-item-nav-line" :style="{width: 'calc(100% /' + (navList.e3DItems.length * 4 + 1) + ')'}"></div>
        </div>

        <div class="module-item-content">
          <loading v-if="loading" />
          <div class="module-item-progress flex0">
            <div v-for="item in navList.e3DItems" :key="item.id" :style="{width: 'calc(100% /' + (navList.e3DItems.length + 0.25) + ')'}">
              <div class="module-item-progress-item tc" v-if="item.id === params.id">
                <div class="progress-box">
                  <div class="module-item-progress-shade flex" :style="{width: Math.floor((item.finish / item.total) * 100) + '%'}">
                    <img src="@/assets/PC/Fence/progressBar.png"/>
                  </div>
                  <div class="progress-num">
                    {{ item.finish }} / {{ item.total }}
                  </div>
                </div>
                <div class="module-item-nav-plan-text">您的学习进度</div>
              </div>
            </div>
          </div>

          <div class="module-item-body">
            <div v-if="resData.description" class="module-item-body-item-description">{{ resData.description }}</div>
            <div class="module-item-body-item-List flex4">
              <div class="module-item-body-item-List-item" v-for="itemChild in resData.courses" :key="itemChild.id" @click="goDetail(itemChild.id)">
                <div v-if="itemChild.status === 0" class="tc module-item-body-item-List-item-disabled" v-html="titleChange(itemChild.title)">
                </div>
                <div v-if="itemChild.status === 1" class="tc module-item-body-item-List-item-living" v-html="titleChange(itemChild.title)">
                </div>
                <div v-if="itemChild.status === 2" class="tc module-item-body-item-List-item-success" v-html="titleChange(itemChild.title)">
                </div>
              </div>
            </div>

            <div class="module-item-body-item" v-for="(item, index) in resData.dataList" :key="index">
              <div v-if="item.title" class="module-item-body-item-title" :class="item.courses.length === 0 ? 'module-item-head-title' : ''">{{ item.title }}</div>
              <div class="module-item-body-item-List flex4">
                <div class="module-item-body-item-List-item" v-for="itemChild in item.courses" :key="itemChild.id" @click="goDetail(itemChild.id)">
                  <div v-if="itemChild.status === 0" class="tc module-item-body-item-List-item-disabled" v-html="titleChange(itemChild.title)">
                  </div>
                  <div v-if="itemChild.status === 1" class="tc module-item-body-item-List-item-living" v-html="titleChange(itemChild.title)">
                  </div>
                  <div v-if="itemChild.status === 2" class="tc module-item-body-item-List-item-success" v-html="titleChange(itemChild.title)">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 一决高下 -->
      <div class="compete">
        <div class="module-item">
          <div class="module-item-title">
            <img src="@/assets/PC/fenceDetail/praise1.png" alt="">
            <h1>一决高下</h1>
            <img src="@/assets/PC/fenceDetail/praise1.png" alt="">
          </div>
        </div>
        <div class="final-test-btn flex0 cur" @click="finalTest">
          <span class="mr">学习测试</span>
          <i class="el-icon-right"></i>
        </div>
      </div>

      <!-- 畅享学习礼遇 -->
      <div class="module-item">
        <div class="module-item-title">
          <img src="@/assets/PC/fenceDetail/praise1.png" alt="">
          <h1>畅享学习礼遇</h1>
          <img src="@/assets/PC/fenceDetail/praise1.png" alt="">
        </div>
        <div class="rules">
          <div class="ruleList-item" v-for="item in ruleList" :key="item.id">
            <div class="ruleList-item-title">
              <i class="el-icon-position"></i>
              <span>{{ item.title }}</span>
            </div>
            <div class="rules-item flex" v-for="itemChild in item.rules" :key="itemChild.id">
              <div class="rules-item-l">
                <div class="rules-item-line"></div>
              </div>
              <div class="rules-item-r">
                <div><b> {{ itemChild.title }} </b></div>
                <p> {{ itemChild.introduction }} </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 登录执行弹窗 -->
      <shadeAlert :shadeConfig="shadeConfig" @closeAlert="closeAlertChange"/>
    </div>
    <!-- <contactUs></contactUs> -->
  </div>
</template>

<script>
import swiper from '@/components/Swiper.vue'
import breadcrumb from '@/components/Breadcrumb/index.vue'
import shadeAlert from "@/components/shadeAlert/index.vue"
import loading from "@/components/loading/index.vue"
import contactUs from "@/components/contactUs/contactUs.vue"

import { BasicData, E3DInfo } from '@/api/E3D.js'
import { GetShareData, GetWxShares } from '@/utils/common'
import { mapState } from 'vuex'

export default {
  name: 'E3D-Video',
  data () {
    return {
      loading: false,
      banners: [],
      refer: [
        { name: '首页', href: '/' },
        { name: '习剑', href: '/Home/Fence/FenceIndex' },
        { name: 'E3D 视频'}
      ],
      // !E3D列表分布
      navList: {
        abstract: '',
        e3DItems: []
      },
      // !选中参数
      params: {
        id: 101
      },
      // !
      resData: {
        description: '',
        dataList: [],
        courses: []
      },
      // !规则明细
      ruleList: [
        {
          id: '10001',
          title: '个人Top奖',
          rules: [
            { id: '10010', title: '学习打卡赢金币', introduction: '每修炼一门剑术，可换50金币，修炼所有剑术后可获2250个金币，金币可换多重好礼；' },
            { id: '10011', title: '进阶学习赢金币', introduction: '每修炼一个版块剑术，可额外获得500金币，修炼完所有版块可额外获得2500金币；'},
            { id: '10012', title: '考试赢限定勋章', introduction: '学习测试正确率达到80%及以上，视为取胜，并奖励1000个金币以及限定勋章。'},
          ],
        },
        {
          id: '10002',
          title: '企业Top奖',
          rules: [
            { id: '10013', title: '人才发展奖', introduction: '员工学习数Top5的企业，获得剑盟社区banner位为期一周的展示特权。'}
          ],
        }
      ],
      // !弹窗配置
      shadeConfig: {},
    }
  },
  created() {
    if(this.$route.query.u) {
      this.$store.commit('e3dinviterId', this.$route.query.u)
    }
  },
  mounted () {
    if(this.clientWidth > 768) {
      this.banners = [
        {
          imgUrl: require('@/assets/PC/Fence/banner1.png'),
          url: '',
          context: ''
        }
      ]
    } else {
      this.banners = [
        {
          imgUrl: require('@/assets/PC/Fence/banner1-m.png'),
          url: '',
          context: ''
        }
      ]
    }

    this.GetNavList();
    GetShareData(1, "E3DVideo", '');
    const share = {
      title: '邀你来战！ AVEVA E3D 剑法秘籍',
      // desc: '一站式进阶“习剑”，享多重惊喜礼遇'
    }
    GetWxShares(share);
  },
  methods: {
    // !获取视频分类
    GetNavList () {
      BasicData().then(res => {
        if (res.status === 1) {
          this.navList = res.data;
          if(res.data.e3DItems.length) {
            this.params.id = res.data.e3DItems[0].id
            this.GetE3DInfo()
          }
        } else {
          this.$toast(res.message)
        }
      })
    },
    // !获取视频分类详情
    GetE3DInfo () {
      this.loading = true
      E3DInfo(this.params).then(res => {
        if(res.status === 1) {
          this.resData = res.data
          this.loading = false
        } else {
          this.$toast(res.message);
          this.loading = false
        };
      });
    },
    // !学习测试系统
    finalTest () {
      if(!this.token) {
        this.shadeConfig = {
          show: true, // 展示隐藏弹窗
          closeIcon: true, // 是否需要关闭按钮
          title: "您还没有登录，<br/>请登录后再来考试吧！",
          text: "", // 弹窗内容
          btnType: "link", // 按钮形式
          btnList: [
            {
              text: '已有剑盟账号，前往登录',
              link: '/Login?redirectUrl=' + this.$route.fullPath,
            },
            {
              text: '没有账号，立即注册',
              link: '/Register?redirectUrl=' + this.$route.fullPath,
            },
          ],
        };
      } else if(this.Infos.isSubmitExam) {
        this.$toast('您已通过学习测试！')
        return false
      } else {
        this.$router.push('/Home/GraduationTest')
      }
    },
    // !视频模块切换
    tabType (id) {
      if(this.params.id !== id) {
        this.params.id = id;
        this.GetE3DInfo();
      }
    },
    // !前往视频详情
    goDetail (id) {
        this.$router.push('/Home/E3DVideoDetail?id=' + id)

    //   if(!this.token) {
    //                     // 取消登录限制
    //     this.shadeConfig = {
    //       show: true, // 展示隐藏弹窗
    //       closeIcon: true, // 是否需要关闭按钮
    //       title: "您还没有登录，<br/>请登录后再来学习吧！",
    //       text: "", // 弹窗内容
    //       btnType: "link", // 按钮形式
    //       btnList: [
    //         {
    //           text: '已有剑盟账号，前往登录',
    //           link: '/Login?redirectUrl=' + this.$route.fullPath,
    //         },
    //         {
    //           text: '没有账号，立即注册',
    //           link: '/Register?redirectUrl=' + this.$route.fullPath,
    //         },
    //       ],
    //     };
    //   } else {
    //     this.$router.push('/Home/E3DVideoDetail?id=' + id)
    //   }
    },
    // !title 换行符 转化
    titleChange (title) {
      const replaceRegex = /(\n\r|\r\n|\r|\n)/g;
      return title.replace(replaceRegex, '<br/>');
    },
    // !关闭登录指引弹窗
    closeAlertChange(state) {
      this.shadeConfig.show = state;
    },
  },
  components: {
    swiper,
    breadcrumb,
    shadeAlert,
    contactUs,
    loading
  },
  computed: {
    ...mapState(['token', 'clientWidth', 'Infos'])
  },
}
</script>

<style lang="scss">
  @import './index.scss'
</style>